import React, { startTransition } from "react";

interface SyncPaginationProps {
  lazyLoadPagination: boolean;
  pagination: boolean;
  query: { [key: string]: string | number | null | undefined };
  page: number;
  maxPages: number;
  setPage: (pageNumber: number) => void;
  setReplaceData: (val: boolean) => void;
}

export function useSyncPagination({
  lazyLoadPagination,
  pagination,
  query,
  page,
  maxPages,
  setPage,
  setReplaceData,
}: SyncPaginationProps) {
  React.useEffect(() => {
    if (!lazyLoadPagination && pagination && query.page !== 1) {
      startTransition(() => {
        setReplaceData(true);
        setPage(query.page as number);
      });
    }

    if (lazyLoadPagination && Number(query?.page) > page) {
      const targetPage = Number(query.page);

      const loadNextPage = (page: number) => {
        if (page < targetPage && page < maxPages) {
          setTimeout(() => {
            startTransition(() => {
              setReplaceData(false);
              setPage(page + 1);
            });
          }, 3250); // Adjust delay as needed
        }
      };

      loadNextPage(Number(page));
    }
  }, [
    lazyLoadPagination,
    pagination,
    page,
    query.page,
    setPage,
    setReplaceData,
    maxPages,
  ]);
}
