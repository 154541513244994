function applySameHeightForClass(container: HTMLElement, className: string) {
  const elements = container.querySelectorAll(className);
  let maxHeight = 0;

  elements.forEach((element) => {
    const el = element as HTMLElement;
    el.style.height = "auto"; // Reset height
    const thisHeight = el.offsetHeight;
    if (thisHeight > maxHeight) {
      maxHeight = thisHeight;
    }
  });

  elements.forEach((element) => {
    const el = element as HTMLElement;
    el.style.height = `${maxHeight}px`;
  });
}

export function applySameHeight(container: HTMLElement) {
  const classes = [
    ".same-height",
    ".same-height2",
    ".same-height3",
    ".same-height4",
    ".same-min-height",
  ];
  classes.forEach((className) => {
    applySameHeightForClass(container, className);
  });
}

export function initializeSameHeight() {
  window.addEventListener("load", () => {
    document.querySelectorAll(".same-height-all-group").forEach((group) => {
      applySameHeight(group as HTMLElement);
    });
    if (window.innerWidth > 688) {
      document.querySelectorAll(".same-height-group").forEach((group) => {
        applySameHeight(group as HTMLElement);
      });
    }
  });
}

export function applyHeightOnRouteChange() {
  document.querySelectorAll(".same-height-all-group").forEach((group) => {
    applySameHeight(group as HTMLElement);
  });
  if (window.innerWidth > 688) {
    document.querySelectorAll(".same-height-group").forEach((group) => {
      applySameHeight(group as HTMLElement);
    });
  }

  window.addEventListener("resize", resizeHeight);
}

const debouncedResizeHeight = debounce(() => {
  document.querySelectorAll(".same-height-all-group").forEach((group) => {
    applySameHeight(group as HTMLElement);
  });
  if (window.innerWidth > 688) {
    document.querySelectorAll(".same-height-group").forEach((group) => {
      applySameHeight(group as HTMLElement);
    });
  } else {
    document.querySelectorAll(".same-height").forEach((element) => {
      const el = element as HTMLElement;
      el.style.height = "auto";
    });
  }
}, 100);

export function resizeHeight() {
  debouncedResizeHeight();
}

function debounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number,
): T {
  let timeout: NodeJS.Timeout;
  return function executedFunction(...args: Parameters<T>) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  } as T;
}
