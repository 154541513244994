"use client";

import React, { startTransition } from "react";
import useSWR from "swr";
import { IPost } from "@/types/wordpress-types";

const fetcher = (url: string) =>
  fetch(url).then(async (res) => {
    const data = await res.json();

    let maxPages = 0;

    if (res.headers.get("X-WP-TotalPages")) {
      try {
        maxPages = parseInt(res.headers.get("X-WP-TotalPages")!);
      } catch (e) {
        console.error("Error parsing max pages: ", e);
      }
    }

    return {
      maxPages,
      data,
    };
  });

// Define an interface that ensures the presence of an `id` or `ID` field
interface PostWithId {
  id?: number | string;
  ID?: number | string;
}

// Define the options interface
interface UsePaginationOptions<T extends PostWithId> {
  requestUrl: string;
  perPage: number;
  queryParams?: Record<string, string>;
  setGridElements?: React.Dispatch<React.SetStateAction<T[]>>;
  initialData?: boolean;
  mergeOrReplace?: "merge" | "replace";
  fetchData?: boolean;
  suspense?: boolean;
  fallback?: { [key: string]: { data: { data: IPost[] }; maxPages: number } };
}

/**
 * Adds lazy loading to given data
 * @param requestUrl - base URL to fetch data from e.g. http://localhost:3001/wp-json/bright/v1/issues
 * @param setGridElements - Function to set the grid elements
 * @param perPage - Number of elements to fetch per page
 * @param initialData
 * @param queryParams - Additional query parameters to add to the request
 * @param mergeOrReplace
 * @param fetchData
 * @returns
 */
export function usePaginationWithMaxPages<T extends PostWithId>({
  requestUrl,
  perPage,
  queryParams = {},
  setGridElements,
  initialData,
  suspense,
  fallback,
  mergeOrReplace = "merge",
  fetchData = true,
}: UsePaginationOptions<T>) {
  const [maxPages, setMaxPages] = React.useState<number>(-1);
  const [page, setPage] = React.useState(1);

  const buildFetchUrl = (
    page: number,
    queryParams: Record<string, string> = {},
  ) => {
    const fetchUrl = new URL(requestUrl);
    fetchUrl.searchParams.append("per_page", perPage.toString());
    fetchUrl.searchParams.append("page", page.toString());

    Object.entries(queryParams).forEach(([key, value]) => {
      fetchUrl.searchParams.append(key, value);
    });

    return fetchUrl.toString();
  };

  const fetchUrl = buildFetchUrl(page, queryParams);

  let suspenseProp = {};
  let fallbackProp = {};

  if (suspense) {
    suspenseProp = { suspense };
  }

  if (fallback) {
    fallbackProp = { fallback: fallback };
  }

  const { data, isLoading, error } = useSWR<{ data: T[]; maxPages: number }>(
    fetchData ? (fetchUrl.toString() as string) : null,
    fetcher,
    {
      ...suspenseProp,
      ...fallbackProp,
    },
  );

  React.useEffect(() => {
    // Helper function to normalize the ID key
    function normalizePosts(posts: T[]) {
      if (!posts || posts.length === 0) {
        return [];
      }

      return posts?.map((post) => {
        // Ensure that each post has an `id` by using `id` or `ID` property
        const normalizedId = (post as any).id || (post as any).ID; // Use `any` here for flexibility
        return { ...post, id: normalizedId } as T;
      });
    }

    if (data?.data) {
      setMaxPages(data?.maxPages || 0);

      if (!initialData && mergeOrReplace === "merge" && setGridElements) {
        // Use functional updates to ensure you're working with the most recent state
        setGridElements((prevGridElements) => {
          let updatedGridElements = [...prevGridElements];

          if (
            Array.isArray(updatedGridElements) &&
            updatedGridElements.length > 0
          ) {
            updatedGridElements = normalizePosts(updatedGridElements);
          }

          // Remove duplicates by filtering out posts that already exist in the state
          const newPosts = Array.isArray(data?.data)
            ? data?.data?.filter((post2) => {
                return !updatedGridElements.some(
                  (post) => post.id === post2.id,
                );
              })
            : [];

          // Add new posts to the grid elements
          updatedGridElements = [...updatedGridElements, ...newPosts];

          return updatedGridElements;
        });
      } else if (
        initialData &&
        page !== 1 &&
        mergeOrReplace === "merge" &&
        setGridElements
      ) {
        // Use functional updates to ensure you're working with the most recent state
        setGridElements((prevGridElements) => {
          let updatedGridElements = [...prevGridElements];

          if (
            Array.isArray(updatedGridElements) &&
            updatedGridElements.length > 0
          ) {
            updatedGridElements = normalizePosts(updatedGridElements);
          }

          // Remove duplicates by filtering out posts that already exist in the state
          const newPosts = Array.isArray(data?.data)
            ? data?.data?.filter((post2) => {
                return !updatedGridElements.some(
                  (post) => post?.id === post2?.id,
                );
              })
            : [];

          // Add new posts to the grid elements
          updatedGridElements = [...updatedGridElements, ...newPosts];

          return updatedGridElements;
        });
      } else if (mergeOrReplace === "replace" && setGridElements) {
        setGridElements(data.data);
      }
    }
  }, [isLoading, data, initialData, page, setGridElements, mergeOrReplace]);

  // const prefetch = async (queryParams: Record<string, string>) => {
  //   const prefetchUrl = buildFetchUrl(1, queryParams);  // Always prefetch the first page
  //   await mutate(prefetchUrl, fetcher(prefetchUrl));
  // };

  return {
    data,
    isLoading,
    error,
    page,
    setPage,
    maxPages,
    // prefetch,
  };
}
