import { Column, Grid } from "@/components/layout/Grid";
import React from "react";
import Image from "next/image";
import { MediaContent } from "@/components/cards/CardElements";

interface LoadingStateProps {
  columns?: number;
}

export function SuspenseCards({ columns = 5 }: LoadingStateProps) {
  return (
    <div className="wrapper min-h-[60vh]">
      <Grid
        columns={columns}
        classes="min-h-[40vh] relative same-height-all-group"
      >
        {/* Columns */}
        {[...Array(15)].map((post, index) => (
          <Column key={`skeleton-${index}`} classes={"relative"}>
            <MediaContent classes="relative sq-ratio animate-pulse">
              <Image
                src={"/skeleton-post.svg"}
                alt={""}
                fill
                className={"object-cover object-center"}
              />
            </MediaContent>
          </Column>
        ))}
      </Grid>
    </div>
  );
}
