import React from "react";

interface IntersectionObserverProps {
  ref: React.RefObject<HTMLElement>;
  query?: { [key: string]: string | number | null | undefined };
  threshold?: number;
}

export function useIntersectionObserver({
  ref,
  query,
  threshold = 0.1,
}: IntersectionObserverProps) {
  const [isInView, setIsInView] = React.useState<boolean>(false);

  React.useEffect(() => {
    setTimeout(() => {
      const currentRef = ref.current; // Capture the current value of the ref

      if (!currentRef) return;

      // Set up Intersection Observer to detect when the related posts section is in view
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsInView(true);
          }
        },
        { threshold: threshold }, // Trigger when 10% of the element is visible
      );

      observer.observe(currentRef);

      return () => {
        observer.unobserve(currentRef);
      };
    }, 4000);
  }, [ref, threshold, query?.category, query?.page, query?.order]);

  return { isInView };
}
