"use client";

// External imports
import React, { useDeferredValue, useTransition } from "react";
import dynamic from "next/dynamic";
import { useSearchParams, usePathname } from "next/navigation";
import clsx from "clsx";
import { preload } from "swr";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation } from 'swiper/modules';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';

// Internal imports
import { getDefaultACFSettings } from "@/helpers/getDefaultACFSettings";
import { resizeHeight } from "@/lib/sameHeight";
import { Grid, Column } from "@/components/layout/Grid";
import { LayoutIntro } from "@/components/LayoutIntro";
import { ViewMoreLink } from "@/components/buttons/ViewMoreLink";
import { usePaginationWithMaxPages } from "@/hooks/usePaginationWithMaxPages";
import { useSyncPagination } from "@/hooks/useSyncPagination";
import { useIntersectionObserver } from "@/hooks/useIntersectionObserver";
import { usePrefetchCategoryFilters } from "@/hooks/usePrefetchCategoryFilters";

// Type Imports
import type { IQueryPosts } from "@/types/ACFLayouts/QueryPosts";
import { IPost } from "@/types/wordpress-types";
import { withSuspense } from "@/components/hoc/withSuspense";
import { SuspenseCards } from "@/components/skeletons/SuspenseCards";
import { HCard, HCardsContainer, VCard } from "@/components/cards/CardElements";

// lazy load components
const Pagination = dynamic(() =>
  import("@/components/ui/pagination/Pagination").then((mod) => mod.Pagination),
);
const LoadingCards = dynamic(() =>
  import("@/components/skeletons/LoadingCards").then((mod) => mod.LoadingCards),
);
const ButtonSpinner = dynamic(() =>
  import("@/components/buttons/ButtonSpinner").then((mod) => mod.ButtonSpinner),
);
const ACFCardBuilder = dynamic(() =>
  import("@/components/acfFlexibleContent/ACFCardBuilder").then(
    (mod) => mod.ACFCardBuilder,
  ),
);
const OrderByButtons = dynamic(
  () => import("@/components/filterButtons/orderByButtons"),
  { ssr: false },
);
const CategoryFilters = dynamic(
  () => import("@/components/filterButtons/categoryFilters"),
  { ssr: false },
);

type Props = {
  data: IQueryPosts;
  nested?: boolean;
};

type QueryParams = {
  [key: string]: string | number | null | undefined;
};

// Define the fetcher function
const fetcher = (url: string) =>
  fetch(url).then(async (res) => {
    const data = await res.json();
    let maxPages = 0;
    if (res.headers.get("X-WP-TotalPages")) {
      try {
        maxPages = parseInt(res.headers.get("X-WP-TotalPages")!);
      } catch (e) {
        console.error("Error parsing max pages of issue-library: ", e);
      }
    }
    return { maxPages, data };
  });

export const AdvancedQueryPosts = withSuspense(
  ({ data, nested = false }: Props) => {
    const searchParams = useSearchParams();
    const pathname = usePathname();

    const query: QueryParams = {
      order:
        (searchParams.get("order") as "asc" | "desc" | "relevance") || "desc",
      category: searchParams.get("category") || "all",
      page: searchParams.get("page") || 1,
    };

    // now you got a read/write object
    const currentParams = new URLSearchParams(
      Array.from(searchParams.entries()),
    ); // -> has to use this form

    // Refs
    const orderMenuRef = React.useRef<HTMLUListElement | null>(null);
    const catMenuRef = React.useRef<HTMLUListElement | null>(null);
    const lazyLoadPaginationRef = React.useRef<HTMLButtonElement | null>(null);
    const paginationRef = React.useRef<HTMLDivElement | null>(null);

    // If it is the default state of the component
    // i.e. category is 'all', order is 'desc', page is 1...
    // Then use the default data already fetched from WP API for this component...
    const defaultStateCheck =
      query.category === "all" &&
      query.order === "desc" &&
      (data.lazy_load_pagination ||
        (data.pagination && query.page === "1") ||
        !(data.lazy_load_pagination && !data.pagination));

    // State
    const [isPending, startTransition] = useTransition();
    const [posts, setPosts] = React.useState<IPost[]>(() =>
      defaultStateCheck ? data.posts : [],
    );
    const deferredPosts = useDeferredValue(posts);
    const [initialData, setInitialData] =
      React.useState<boolean>(defaultStateCheck);
    const [replaceData, setReplaceData] = React.useState<boolean>(false);
    const [openMenu, setOpenMenu] = React.useState<string | null>(null);

    const baseUrl = `${process.env.NEXT_PUBLIC_API_URL}/wp-json/wp/v2/${data?.post_type}`;

    /****************************** Component Setup ****************************/

    // Default ACF Settings
    const {
      hide,
      paddings,
      margins,
      classes,
      id,
      backgroundColor,
      bgImgStyles,
    } = getDefaultACFSettings(data);

    // Group all main layout classes here
    const combinedClasses = clsx(
      "relative",
      "acf-layout",
      "advanced-query-posts-block",
      "full-wrapper",
      paddings,
      margins,
      classes,
      data.display_in_a_slider ? "slider" : "columns",
    );

    let sliderOptions: {
      [key: string]: any;
      slides: {
        perView?: number;
        spacing?: number;
      };
    } = {
      slides: {
        perView: 1,
        spacing: 20,
      },
    };

    if (data.orientation === "horizontal") {
      // Override the panelsPerView property
      sliderOptions.slides.perView =
        data.horizontal_columns === "stack" ? 1 : 2;
    } else {
      // Override the perPage property
      sliderOptions.slides.perView = +data.number_of_columns;
    }

    /****************************** Handle Functions ****************************/
    function handlePaginationClick() {
      startTransition(() => {
        setReplaceData(true);
      });
    }

    // Handle Function for 2x filter menus - Category (mobile) & Orderby
    const handleToggle = (menuId: string) => {
      setOpenMenu((prevMenu) => (prevMenu === menuId ? null : menuId));
    };

    function handleCategoryChange(slug: string) {
      // No longer only initial data...
      if (initialData) {
        startTransition(() => {
          setInitialData(false);
        });
      }

      if (slug !== query.category) {
        startTransition(() => {
          setPage(1);
          setPosts([]);
          setReplaceData(true);
          if (slug === "all") {
            setInitialData(true);
            setPosts(data.posts);
            window.history.pushState(null, "", pathname);
          } else {
            currentParams.delete("page");
            currentParams.set("category", slug);
            currentParams.set("order", "desc");
            window.history.pushState(null, "", `?${currentParams}`);
          }
        });
      }
    }

    function handleOrderClick(order: "asc" | "desc" | "relevance") {
      // No longer only initial data...
      if (initialData) {
        startTransition(() => {
          setInitialData(false);
        });
      }

      if (order !== query.order) {
        startTransition(() => {
          setPage(1);
          setPosts([]);
          setReplaceData(true);
          if (query.category === "all" && order === "desc") {
            window.history.pushState(null, "", pathname);
          } else {
            currentParams.set("order", order);
            currentParams.set("page", "1");
            window.history.pushState(null, "", `?${currentParams}`);
          }
        });
      }
    }

    /****************************** Hooks ****************************/

    // Main custom Hook responsible for fetching data and handling lazy load pagination...
    const { isLoading, maxPages, page, setPage } =
      usePaginationWithMaxPages<IPost>({
        requestUrl: `${baseUrl}`,
        perPage: query.per_page
          ? parseInt(query.per_page as string, 10)
          : +data.number_of_posts,
        queryParams: {
          orderby: "date",
          order: query.order as string,
          ...(query.category !== "all" &&
            (() => {
              const term = data.filter_terms.find(
                (term) => term.slug === (query.category as string),
              );
              return term ? { [term.taxonomy]: term.term_id.toString() } : {};
            })()),
        },
        setGridElements: setPosts,
        initialData: initialData,
        fetchData:
          data.pagination ||
          data.lazy_load_pagination ||
          data.category_filters ||
          data.order_filters,
        mergeOrReplace: replaceData ? "replace" : "merge",
      });

    // Custom hook to prefetch Category Filters
    usePrefetchCategoryFilters({
      baseUrl,
      filterTerms: data?.filter_terms,
      lazyLoadPagination: data?.lazy_load_pagination,
      query,
      isLoading,
      page,
    });

    // Custom hook to sync pagination when sharing a URL
    // If page=3 on page load, we need to load 2 more pages,
    // programmatically to sync the pagination...
    useSyncPagination({
      lazyLoadPagination: data?.lazy_load_pagination,
      pagination: data?.pagination,
      query,
      page,
      maxPages,
      setPage,
      setReplaceData,
    });

    // Resize posts when posts change...
    React.useEffect(() => {
      setTimeout(() => resizeHeight(), 250);
    }, [deferredPosts]);

    const { isInView } = useIntersectionObserver({
      ref: data?.lazy_load_pagination ? lazyLoadPaginationRef : paginationRef,
      query,
    });

    // Prefetch next page for quicker loading if pagination is now in view...
    if (isInView) {
      // Check this isn't already being handled by the custom useSyncPagination hook...
      if (
        !isLoading &&
        !(data.lazy_load_pagination && Number(query?.page) > page)
      ) {
        // Check if there's a next page
        if (
          +page < +maxPages &&
          (data.pagination || data.lazy_load_pagination)
        ) {
          let nextPageUrl = `${baseUrl}?per_page=${data.number_of_posts}&page=${+page + 1}&orderby=date&order=${query.order}`;

          if (query.category !== "all") {
            const term = data.filter_terms.find(
              (term) => term.slug === (query.category as string),
            );
            if (term) {
              nextPageUrl += `&${term.taxonomy}=${term.term_id}`;
            }
          }
          preload(nextPageUrl, fetcher); // Use the preload function to prefetch the next page
        }
      }
    }

    if (hide) {
      return <></>;
    }

    return (
      <>
        <div
          id={id}
          className={`${combinedClasses}`}
          style={{ ...bgImgStyles, backgroundColor }}
        >
          <div className={`${!nested ? data.wrapper_width : ""}`}>
            {/*  Grid Cards - No Slider
          - Check first to see if we have any posts and fallback if not...
          */}
            {!data.display_in_a_slider && (
              <>
                {/* Intro */}
                {data.intro && (
                  <LayoutIntro intro={data.intro}>
                    {data.view_more_link &&
                      typeof data.view_more_link === "object" && (
                        <ViewMoreLink view_more_link={data.view_more_link} />
                      )}

                    {(data.order_filters || data.category_filters) && (
                      <div className="flex justify-end gap-2.5">
                        {data.category_filters && data?.filter_terms && (
                          <CategoryFilters
                            ref={catMenuRef}
                            terms={data.filter_terms}
                            activeTerm={query?.category as string}
                            setTerm={handleCategoryChange}
                            isOpen={openMenu === "category-filter"}
                            handleToggle={handleToggle}
                          />
                        )}
                        {data.order_filters && (
                          <OrderByButtons
                            ref={orderMenuRef}
                            value={query?.order as "asc" | "desc" | "relevance"}
                            handleClick={handleOrderClick}
                            isOpen={openMenu === "order-posts"}
                            handleToggle={handleToggle}
                          />
                        )}
                      </div>
                    )}
                  </LayoutIntro>
                )}

                {/*  Loading State if not loading more...  */}
                {isLoading && !initialData && replaceData ? (
                  <LoadingCards columns={+data.number_of_columns} />
                ) : (
                  <>
                    {/* Horizontal Cards
                     - These don't use the Grid component
                     hence being seperated out from the Vertical Cards.
                     */}
                    {data.orientation === "horizontal" && (
                      <>
                        <HCardsContainer
                          columns={data.horizontal_columns}
                          direction={data.zz_direction}
                          stacking={data.stacking}
                        >
                          {/* Columns */}
                          {deferredPosts.map((post, index) => (
                            <HCard
                              classes={data.card_classes}
                              key={post.ID}
                              post={post}
                            >
                              <ACFCardBuilder data={data} post={post} />
                            </HCard>
                          ))}
                        </HCardsContainer>
                      </>
                    )}

                    {/* Vertical Cards  */}
                    {data.orientation === "vertical" && (
                      <>
                        <Grid
                          classes={`relative same-height-all-group ${isPending ? "opacity-50" : "opacity-100"}`}
                          columns={+data.number_of_columns}
                        >
                          {/* Columns */}
                          {deferredPosts.map((post, index) => (
                            <Column key={`post-${index}`}>
                              <VCard classes={data.card_classes} post={post}>
                                <ACFCardBuilder data={data} post={post} />
                              </VCard>
                            </Column>
                          ))}
                        </Grid>

                        {!isLoading &&
                          data.pagination &&
                          !data.lazy_load_pagination && (
                            <>
                              {/*  Pagination  */}
                              <div
                                ref={paginationRef}
                                className="flex items-center justify-end mt-10 space-x-2"
                              >
                                <Pagination
                                  onClick={handlePaginationClick}
                                  setPage={setPage}
                                  currentPage={+page}
                                  maxPages={maxPages}
                                  params={currentParams}
                                />
                              </div>
                            </>
                          )}

                        {data.lazy_load_pagination && (
                          <button
                            ref={lazyLoadPaginationRef}
                            className={clsx(
                              `btn podcast-btn w-full mt-10 font-medium`,
                              page >= maxPages && "opacity-50",
                              isLoading || isPending ? "!bg-pn-black-base" : "",
                            )}
                            onClick={() => {
                              startTransition(() => {
                                setReplaceData(false);
                                setPage((prev) => prev + 1);
                                currentParams?.set("page", String(page + 1));
                                window.history.pushState(
                                  null,
                                  "",
                                  `?${currentParams}`,
                                );
                              });
                            }}
                            disabled={page >= maxPages || isLoading}
                          >
                            {isLoading ? <ButtonSpinner /> : "Load More"}
                          </button>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {/* Slider of Posts
           - Check first to see if we have any posts and fallback if not...
          */}
            {data.display_in_a_slider && (
              <>
                {deferredPosts.length > 0 ? (
                  <>
                    {data.orientation === "horizontal" ? (
                      <HCardsContainer
                        columns={data.horizontal_columns}
                        direction={data.zz_direction}
                        stacking={data.stacking}
                      >
                        {/* Intro */}
                        {data.intro && <LayoutIntro intro={data.intro} />}

                        <Swiper
                          modules={[EffectFade, Navigation]}
                          effect={'fade'}
                          navigation={{ nextEl: '.swiper-next', prevEl: '.swiper-prev', enabled: true }}
                          className="same-height-group"
                          onInit={(swiper) => setTimeout(() => swiper.update(), 300)}
                        >
                          <div className="swiper-arrows flex z-[5] gap-2.5 !mt-5">
                            <div className="swiper-arrow swiper-prev"></div>
                            <div className="swiper-arrow swiper-next"></div>
                          </div>
                          {deferredPosts &&
                            deferredPosts.map((post) => (
                              <SwiperSlide key={post.ID || post.id}>
                                <HCard classes={data.card_classes} post={post}>
                                  <ACFCardBuilder data={data} post={post}/>
                                </HCard>
                              </SwiperSlide>
                            ))}
                        </Swiper>
                      </HCardsContainer>
                    ) : (
                      <>
                        {/* Intro */}
                        {data.intro && <LayoutIntro intro={data.intro}/>}

                        <Swiper
                          modules={[EffectFade, Navigation]}
                          effect={'fade'}
                          navigation={true}
                          className="same-height-group"
                        >
                          {deferredPosts &&
                            deferredPosts.map((post) => (
                              <SwiperSlide key={post.ID || post.id}>
                                <VCard classes={data.card_classes} post={post}>
                                  <ACFCardBuilder data={data} post={post} />
                                </VCard>
                              </SwiperSlide>
                            ))}
                        </Swiper>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  },
  SuspenseCards,
);
