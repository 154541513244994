import { ITerm } from "@/types/wordpress-types";
import React from "react";
import { preload } from "swr";

interface PrefetchCategoryFiltersProps {
  baseUrl: string;
  filterTerms: ITerm[];
  lazyLoadPagination: boolean;
  isLoading: boolean;
  query: { [key: string]: string | number | null | undefined };
  page: number;
}

// Define the fetcher function
const fetcher = (url: string) =>
  fetch(url).then(async (res) => {
    const data = await res.json();
    let maxPages = 0;
    if (res.headers.get("X-WP-TotalPages")) {
      try {
        maxPages = parseInt(res.headers.get("X-WP-TotalPages")!);
      } catch (e) {
        console.error("Error parsing max pages of issue-library: ", e);
      }
    }
    return { maxPages, data };
  });

export function usePrefetchCategoryFilters({
  baseUrl,
  filterTerms,
  lazyLoadPagination,
  isLoading,
  query,
  page,
}: PrefetchCategoryFiltersProps) {
  // useEffect for prefetching page 1 of each category filter - SWR preload function
  React.useEffect(() => {
    if (!filterTerms) {
      return;
    }

    if (!isLoading && !(lazyLoadPagination && Number(query?.page) > page)) {
      // Preload data for all categories in the background
      filterTerms.forEach((term) => {
        const preloadUrl = `${baseUrl}?per_page=15&page=1&orderby=date&order=${query.order}&${term.taxonomy}=${term.term_id}`;

        setTimeout(() => {
          preload(preloadUrl, fetcher); // Use the preload function here
        }, 3000);
      });
    }
  }, [
    baseUrl,
    filterTerms,
    lazyLoadPagination,
    isLoading,
    page,
    query.order,
    query?.page,
  ]);
}
